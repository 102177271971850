<template>
  <q-dialog v-model="isOpen" class="modal-sm">
    <q-card>
      <q-card-section class="text-h6 text-center text-weight-bold q-pb-none">
        {{ $t('Two-Factor Authentication') }}
      </q-card-section>

      <q-card-section>
        <div class="text-subtitle1 text-weight-bold">
          {{ $t('Step 1: Open authenticator app') }}
        </div>

        <div class="text-subtitle2">
          {{ $t('Open authenticator app and find the registration you are trying to log in with.') }}
        </div>
      </q-card-section>

      <q-card-section>
        <div class="text-subtitle1 text-weight-bold">
          {{ $t('Step 2: Enter you code') }}
        </div>

        <div class="text-subtitle2 q-mb-md">
          {{ $t('After you find your registration you just need to enter 6-digit code below.') }}
        </div>

        <digits-input
          :digits="6"
          class="q-px-lg"
          @on-change="handleChange"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          no-caps
          :label="$t('Close')"
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          no-caps
          :label="$t('Submit')"
          @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import DigitsInput from '../tools/DigitsInput.vue'

export default {
  name: 'CredentialsModal',
  emits: ['submit'],
  components: {
    DigitsInput
  },
  data () {
    return {
      isOpen: false,
      text: ''
    }
  },
  methods: {
    close () {
      this.isOpen = false
    },
    open () {
      this.isOpen = true
      this.text = ''
    },
    handleChange (model) {
      this.text = model.join('')
    },
    save () {
      if (!this.text) {
        return
      }

      this.$emit('submit', this.text)
      this.close()
    }
  }
}
</script>

<template>
  <q-dialog v-model="isOpen" class="modal-sm">
    <q-card>
      <q-card-section class="text-h6 text-center text-weight-bold q-pb-none">
        {{ $t('Two-Factor Authentication') }}
      </q-card-section>

      <q-card-section>
        <div class="text-subtitle1 text-weight-bold">
          {{ $t('Step 1: Download an authenticator app') }}
        </div>

        <div class="text-subtitle2">
          {{ $t('Download and install ') }}

          <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
            {{ $t('Google Authenticator') }}
          </a>

          {{ $t('on your phone.') }}
        </div>
      </q-card-section>

      <q-card-section class="text-center q-pb-none">
        <div class="text-subtitle1 text-left text-weight-bold">
          {{ $t('Step 2: Scan the QR Code') }}
        </div>

        <div class="text-subtitle2 text-left q-mb-md">
          {{ $t('Open it. If you use Google Authenticator for first time, it will open you camera automatically, otherwise you will need to tap on button in bottom right and select "Scan QR Code". After that just point your phone at the screen.') }}
        </div>

        <canvas ref="canvas" />

        <div class="text-caption text-center">
          {{ code }}
        </div>
      </q-card-section>

      <q-card-section>
        <div class="text-subtitle1 text-weight-bold">
          {{ $t('Step 3: Verify you code') }}
        </div>

        <div class="text-subtitle2 q-mb-md">
          {{ $t('After successful scan, Google Authenticator will show you the 6-digit code. Enter the code.') }}
        </div>

        <digits-input
          :digits="6"
          class="q-px-lg"
          @on-change="handleChange"
        />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="dark"
          text-color="white"
          no-caps
          :label="$t('Close')"
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          no-caps
          :label="$t('Submit')"
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Utils
import bwipjs from 'bwip-js'

// Components
import DigitsInput from '../tools/DigitsInput.vue'

export default {
  name: 'QrCodeModal',
  components: {
    DigitsInput
  },
  emits: ['submit'],
  data () {
    return {
      isOpen: false,
      code: null,
      qrcode: null,
      error: '',
      text: ''
    }
  },
  methods: {
    close () {
      this.isOpen = false
    },
    handleChange (model) {
      this.text = model.join('')
    },
    open (code, qrcode) {
      this.code = code
      this.qrcode = qrcode
      this.isOpen = true
      this.error = ''
      this.text = ''

      this.$nextTick(() => {
        try {
          bwipjs.toCanvas(this.$refs.canvas, {
            bcid: 'qrcode',
            text: qrcode,
            includetext: true,
            textxalign: 'center'
          })
        } catch (e) {
          this.error = e.message
        }
      })
    },
    save () {
      if (!this.text) {
        return
      }

      this.$emit('submit', this.text)
      this.close()
    }
  }
}
</script>

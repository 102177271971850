<template>
  <div class="row">
    <div
      v-for="(value, i) in model"
      :key="i"
      class="col q-pa-sm"
    >
      <q-input
        :ref="`field:${i}`"
        :model-value="value"
        type="number"
        standout="bg-teal text-white"
        @update:model-value="handleChange(i, $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DigitsInput',
  props: {
    digits: {
      type: Number,
      default () {
        return 6
      }
    }
  },
  data () {
    return {
      model: []
    }
  },
  mounted () {
    this.model = new Array(this.digits).fill('')
  },
  methods: {
    handleChange (i, value) {
      this.model[i] = `${value}`
      this.model[i] = this.model[i][this.model[i].length - 1]

      this.$emit('on-change', this.model)

      if (i + 1 >= this.model.length) {
        return
      }

      const field = this.$refs[`field:${i + 1}`] && Array.isArray(this.$refs[`field:${i + 1}`])
        ? this.$refs[`field:${i + 1}`][0]
        : this.$refs[`field:${i + 1}`]

      if (field) {
        field.focus()
      }
    }
  }
}
</script>
